import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Docs: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.88 24.31h10.385v-2.075H12.88v2.075Zm0-4.155h10.385V18.08H12.88v2.075Zm0-4.155h10.385v-2.075H12.88V16Zm14.535-5.105L22.14 5.61H8.725v24.93H27.42V10.895h-.005ZM10.8 28.465V7.695h10.475l4.06 4.065v16.71H10.8v-.005ZM20.06 3.54l-2.075-2.08H4.57v24.93h2.075V3.54H20.06Z"
      clipRule="evenodd"
    />
  </Icon>
);
