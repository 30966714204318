export const telephoneNumbers = {
  customerServices: '03330 30 83 75',
  newSales: '03330 30 10 00',
  businessDevelopmentManager: '03330 30 83 89',
  newPolicies: '03330 30 10 00',
  renewals: '03330 30 82 31',
  claims: '03330 30 83 76',
  claimSupport: '03330 30 83 90',
  vetSupport: '03330 30 83 90',
  rehomngSupport: '03330 30 83 89',
  breederSupport: '03330 30 83 75',
} as const;
