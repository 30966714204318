import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeColorsKeys } from '../../styles/tokens/colors';

// Interface

export interface DividerProps {
  inverse?: boolean;
  color?: ThemeColorsKeys;
}

// Styles

export const StyledDivider = styled.hr<DividerProps>`
  ${({ theme: { colors }, color, inverse }) => css`
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: ${inverse
      ? colors.brandOne80
      : color
      ? colors[color]
      : colors.brandOne10};
  `};
`;

// JSX

export const Divider: FC<DividerProps> = ({ inverse, color, ...rest }) => (
  <StyledDivider inverse={inverse} color={color} {...rest} />
);

export default Divider;
