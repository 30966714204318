import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Currency: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M13.425 22.92v-4.67h6.205v-2.1h-6.205v-2.805A4.272 4.272 0 0 1 17.69 9.08c1.145 0 2.225.45 3.03 1.265l1.49-1.475a6.31 6.31 0 0 0-4.525-1.89c-3.505-.005-6.36 2.85-6.36 6.36v2.805H9v2.1h2.325v6.77h11.68v-2.1h-9.58v.005Z"
    />
  </Icon>
);
