import { colors } from './colors';

const borders = {
  default: `solid 2px ${colors.brandOne120}`,
  valid: `solid 2px ${colors.brandTwo50}`,
  focus: `solid 2px ${colors.systemFocus}`,
  disabled: `solid 2px ${colors.brandOne50}`,
  error: `solid 2px ${colors.systemError}`,
} as const;

export default borders;

export type ThemeBorders = typeof borders;
export type ThemeBordersKeys = keyof typeof borders;
