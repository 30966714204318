import { getColorTones } from '../../../utils';
import { brandColors } from './brand';

export const systemColors = {
  systemFocus: '#F5BA33',
  systemInformation: brandColors.brandOne,
  systemSuccess: '#4E9C33',
  systemError: '#C92C36',
  ...getColorTones('#C92C36', 'systemError'),
  ...getColorTones('#4E9C33', 'systemSuccess'),
  ...getColorTones(brandColors.brandOne, 'systemInformation'),
} as const;
