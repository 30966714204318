import React, { createContext, useContext, ReactNode, useMemo } from 'react';

interface PageContextProps {
  pageID: string;
}

export const PageContext = createContext<PageContextProps | undefined>(
  undefined
);

export const PageProvider: React.FC<
  PageContextProps & { children: ReactNode }
> = ({ pageID, children }) => {
  const contextValue = useMemo(() => ({ pageID }), [pageID]);
  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};

export const usePage = (): PageContextProps => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};
