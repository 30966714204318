import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  StaticImage,
} from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Interface

export interface ImageProps {
  alt?: string;
  loading?: 'lazy' | 'eager' | undefined;
  src?: ImageDataLike | string;
  borderRadius?: 'all' | 'top' | 'left' | 'bottom' | 'right';
  onClick?: () => any;
}

// Function to Get Border Radius

const getBorderRadius = (borderRadius: string | undefined) => {
  switch (borderRadius) {
    case 'all':
      return '4px';
    case 'top':
      return '4px 4px 0 0';
    case 'left':
      return '4px 0 0 4px';
    case 'bottom':
      return '0 0 4px 4px';
    case 'right':
      return '0 4px 4px 0';
    default:
      return '0';
  }
};

// Styled Component for Border Radius

const StyledImageContainer = styled.div<{ borderRadius?: string }>`
  height: 100%;

  img {
    border-radius: ${({ borderRadius }) => getBorderRadius(borderRadius)};
  }
`;

// JSX

export const Image: FC<ImageProps> = ({
  src,
  alt,
  loading,
  borderRadius,
  ...rest
}) => {
  // if no source

  if (!src) {
    return null;
  }

  // If the image is a simple string just return an img tag

  if (typeof src === 'string') {
    return (
      <StyledImageContainer borderRadius={borderRadius} {...rest}>
        <img src={src} alt={alt ?? ''} />
      </StyledImageContainer>
    );
  }

  // else see if we can get the Gatstby image

  const imageData = getImage(src);
  if (!imageData) {
    return null;
  }

  return (
    <StyledImageContainer borderRadius={borderRadius}>
      <GatsbyImage
        image={imageData}
        alt={alt ?? ''}
        loading={loading}
        {...rest}
      />
    </StyledImageContainer>
  );
};

export default Image;
